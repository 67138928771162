<template>
  <div>
    <h2 class="pl-2">
      {{!this.isAdd ? 'Edytuj' : 'Dodaj nową'}} kategorię
    </h2>
    <v-form v-model="valid" ref="articleForm" lazy-validation class="pb-10">
      <v-card class="mt-10 pa-5">
        <v-row>
          <v-col
            cols="9">
            <h4
              class="mb-5">
              Dane podstawowe
            </h4>
          </v-col>

          <v-col
            v-if="!this.isAdd"
            class="text-right"
            cols="3">
            <v-btn
              color="red"
              class="mr-10 white--text"
              @click="deleteArticle()"
            >
            {{ $t('common.delete') }} kategorię
            <v-icon class="ml-2">mdi-delete</v-icon>
            </v-btn>
          </v-col>
      </v-row>

      <v-row>
        </v-row>
        <v-text-field label="Tytuł"
          v-model='article.name'
          :rules="[...rules.maxLengthTitle, ...rules.required]">
        </v-text-field>

        <span class="custom-label">Plik</span>
        <div v-if="article.fileName">
          <span class="mr-5">
            {{article.fileOriginalName}}
          </span>
          <v-btn
              class="mr-5"
              right
              @click="removeFile()">
              Usuń plik
          </v-btn>
          <v-btn class="ml-2"
              @click="openFile(article.fileName)">
              Otwrz plik w nowej karcie
          </v-btn>
        </div>
        <v-file-input v-else
          counter
          show-size
          truncate-length="15"
          label="Miniaturka"
          v-model='article.file'
          accept=".png, .jpeg, .jpg, .mp4"
          :rules="[...rules.fileType, ...rules.fileSize]">
        </v-file-input>
      </v-card>

      <v-card
        class="mt-10 pa-5"
        v-for="(item, index) in article.articleDetails"
        :key="item.id">
        <AddArticleDetails
          :articleDetails="article.articleDetails[index]"
          :title="'Produkt'"
          :index="index" @remove="removeDetails">
        </AddArticleDetails>
      </v-card>

      <v-card-actions class="justify-end">
        <v-btn
          v-if="!article.isArchival"
          color="green"
          class="mr-4 white--text mt-5 mr-10"
          @click="addDetails">
          Dodaj kolejny produkt
          <v-icon class="ml-2">mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>

      <v-card-actions class="justify-end">
        <v-btn
          v-if="!article.isArchival"
          :disabled="!isAdd && isSaveChangesBtnDisabled ? true : false"
          color="blue"
          class="mr-4 mt-5 mb-5 white--text mr-10"
          @click="submit">
          {{ !isAdd ? 'Zapisz zmiany' : 'Dodaj kategorię'}}
          <v-icon class="ml-2">mdi-archive-arrow-up</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>

    <confirm-delete-dialog
        endPoint="/Article/DeleteArticle"
        ref="confirmDeleteDialog"
        @submit="$router.push({ name: 'Produkty i nowości'})"
      ></confirm-delete-dialog>

    <spinner-dialog ref="SpinnerDialog"></spinner-dialog>
  </div>
</template>

<script>
import {
  displayInfo,
  displayErrors
} from '@/utils/helpers'
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'
import AddArticleDetails from '@/views/Article/AddArticleDetails.vue'
import { ArticleTypesEnum } from '@/utils/enums.js'
import SpinnerDialog from '@/views/Spinner/SpinnerDialog'

export default {
  props: ['type'],
  name: 'AddOrEditProduct',
  components: {
    AddArticleDetails,
    ConfirmDeleteDialog,
    SpinnerDialog
  },
  data: () => ({
    rules: {
      required: [v => !!v || 'Pole wymagane'],
      maxLengthTitle: [v => v.length <= 256 || 'Maksymalna ilosć 256 znaków.'],
      fileType: [v => !v || !!(/(\.jpg|\.jpeg|\.mp4|\.gif|\.png)$/i.exec(v.name)) || 'Błędny plik. Dozwolone rozszerzenie to .jpg, .jpeg, .mp4, .gif, .png'],
      fileSize: [v => !v || v.size < 30000000 || 'Wielkość pliku musi być mniejsza od 30MB']
    },
    isAdd: false,
    isSaveChangesBtnDisabled: false,
    valid: false,
    menu: false,
    article: {
      id: null,
      version: null,
      groupsIds: [],
      name: '',
      description: '',
      file: null,
      fileName: null,
      fileOriginalName: null,
      type: 4,
      from: '',
      to: '',
      isArchival: false,
      articleDetails: []
    },
    articleOrginal: null
  }),
  async created () {
    this.ArticleTypesEnum = ArticleTypesEnum
    const id = this.$route.params.id
    this.article.type = this.type

    if (id) {
      this.article.id = id
      await this.getDataAsync()
    } else {
      this.addDetails()
    }

    this.isAdd = this.article.id === undefined ||
      this.article.id == null ||
      this.article.id === ''

    const groupsResult = await this.$http.get('/Group/GetGroups')
    this.groups = groupsResult.data

    this.$watch(
      'article.type', function (newVal) {
        if (newVal === ArticleTypesEnum.YOU) {
          this.initCalculator()
        } else {
          this.calculator = null
        }
      }
    )
  },
  methods: {
    async getDataAsync () {
      const result = await this.$http.get(`/Article/GetArticle?Id=${this.article.id}`)
      this.article = result.data
      this.articleOrginal = JSON.stringify(result.data)
    },
    addDetails () {
      this.article.articleDetails.push({
        id: null,
        name: '',
        description: '',
        file: null,
        fileName: null,
        fileOriginalName: null
      })
    },
    removeDetails (index) {
      this.article.articleDetails.splice(index, 1)
    },
    removeFile () {
      this.article.fileName = null
      this.article.fileOriginalName = null
    },
    openFile (fileName) {
      window.open(`${process.env.VUE_APP_BASE}uploads/${fileName}`, '_blank')
    },
    deleteArticle () {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.id = this.article.id
    },
    isArticleNotChanged () {
      return this.articleOrginal === JSON.stringify(this.article)
    },
    async submit () {
      this.$refs.articleForm.validate()
      if (!this.valid) {
        return
      }

      this.$refs.SpinnerDialog.dialog = true

      const formData = new FormData()

      formData.append('ValidateDescription', false)
      formData.append('Article.Id', this.article.id)
      formData.append('Article.Name', this.article.name)
      formData.append('Article.Description', this.article.description)
      formData.append('Article.File', this.article.file)
      formData.append('Article.FileName', this.article.fileName ? this.article.fileName : '')
      formData.append('Article.Type', this.article.type)
      formData.append('Article.From', new Date(2000, 12).toDateString())
      formData.append('Article.To', new Date(2050, 12).toDateString())

      if (!this.isAdd) {
        formData.append('Article.Version', this.article.version)
      }

      for (let i = 0; i < this.article.articleDetails.length; i++) {
        formData.append(`Article.ArticleDetails[${i}].Name`, this.article.articleDetails[i].name)
        formData.append(`Article.ArticleDetails[${i}].Description`, this.article.articleDetails[i].description)
        formData.append(`Article.ArticleDetails[${i}].File`, this.article.articleDetails[i].file)
        formData.append(`Article.ArticleDetails[${i}].FileName`, this.article.articleDetails[i].fileName ? this.article.articleDetails[i].fileName : '')
      }

      try {
        const result = await this.$http.post(
          this.isAdd
            ? '/Article/AddArticle'
            : '/Article/UpdateArticle', formData
        )

        if (result.status === 200) {
          const info = `Poprawnie ${this.isAdd ? 'dodano' : 'zaktualizowano'} kategorię: ${this.article.name}`
          displayInfo(info)

          await this.$router.push({
            name: 'Produkty i nowości'
          }
          )
        } else {
          displayErrors('Wystapił błąd')
        }
      } catch (e) {} finally {
        if (this.$refs.SpinnerDialog) { this.$refs.SpinnerDialog.dialog = false }
      }
    }
  },
  watch: {
    article: {
      handler () {
        if (!this.isAdd) {
          if (!this.isArticleNotChanged()) {
            this.isSaveChangesBtnDisabled = false
          } else {
            this.isSaveChangesBtnDisabled = true
          }
        }
      },
      deep: true
    }
  }
}
</script>
