<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="500"
      >
        <v-card>
          <v-card-title class="text-h5">
            {{titleActionText}}
          </v-card-title>
          <v-card-text v-if="message">
            {{message}}
          </v-card-text>
          <v-card-text>
            {{descriptionActionText}}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Anuluj
            </v-btn>

            <v-btn
              color="red darken-1"
              text
              @click="submit"
            >
              Tak
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <spinner-dialog ref="SpinnerDialog"></spinner-dialog>
    </v-row>
  </template>

<script>
import { displayInfo, displayErrors } from '@/utils/helpers'
import SpinnerDialog from '@/views/Spinner/SpinnerDialog'

export default {
  props: {
    endPoint: {
      type: String,
      required: true
    },
    titleActionText: {
      type: String,
      default: 'Potwierdź usunięcie'
    },
    descriptionActionText: {
      type: String,
      default: 'Czy napewno chcesz usunąć?'
    },
    successActionText: {
      type: String,
      default: 'Poprawnie usunięto'
    },
    isMobileApi: {
      type: Boolean,
      default: false
    }
  },
  name: 'confirm-delete-dialog',
  components: {
    SpinnerDialog
  },
  data () {
    return {
      dialog: false,
      message: '',
      id: '',
      useDeleteMethod: false
    }
  },
  methods: {
    async submit () {
      this.$refs.SpinnerDialog.dialog = true
      try {
        let result
        if (this.useDeleteMethod) {
          result = await this.$http.delete(this.endPoint + this.id)
        } else {
          if (this.isMobileApi) {
            result = await this.$http({
              method: 'post',
              url: this.endPoint,
              baseURL: process.env.VUE_APP_BASE_MOBILE + 'v1/',
              data: { id: this.id }
            })
          } else {
            result = await this.$http.post(this.endPoint, { Id: this.id })
          }
        }

        if (result.status === 200) {
          this.$emit('submit')
          this.dialog = false
          displayInfo(this.successActionText)
        }
      } catch (ex) {
        console.log(ex)
        displayErrors('Wystąpił błąd.')
      }
      this.$refs.SpinnerDialog.dialog = false
    }
  }
}
</script>
